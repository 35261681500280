$theme-colors: (
//  "primary": #35539A,
  "primary": #5875BE,
  "secondary": #25AE98,
  "danger": #ff4136
);
$primary:#35539A;

@import url('https://fonts.googleapis.com/css?family=Muli&display=swap');
@import "~bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

html {
    position: relative;
    min-height: 130vh;
}

body{
  background-color: #5875BE05;
}

p, h1, h2,h3, h4 ,h5

p, h1, h2,h3, h4 ,h5, .title{
  font-family: 'Muli', sans-serif;
}

.text-md-primary{
  @media (min-width: 886px){
    color:$primary !important;
  }
  @media (max-width: 886px){
    background-color: transparent !important;
  }
}

.marge-md{
  @media (max-width: 886px){
    margin-top:0px !important;
    margin-bottom: auto !important;
  }
}

.w-md-100{
  @media (min-width: 776px){
    width:100% !important;
  }
}

$white: #fff;

// Misc
$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: $white;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px $primary;
}

.checkmark_animate{
	animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark_animate_false{
	animation: fillFalse .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $primary;
  fill: none;
  animation: stroke .6s $curve forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke .3s $curve .8s forwards;
}

.checkmark__check_false {

  animation: stroke .3s $curve .8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 10;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #44B4AB;
  }
}

@keyframes fillFalse {
  100% {
    box-shadow: inset 0px 0px 0px 30px red;
  }
}

.text-blue{
  color:#23B6D0;
}

.resize-price{
  font-size:1.3rem;
}

.margin-top-mobile{
  margin-top:90vh;
  @media (max-width: 510px){
    margin-top:70vh !important;
  }
}

.margin-top-mobile-array{
  margin-top:100vh;
  @media (max-width: 510px){
    margin-top:80vh !important;
  }
}

.perso-margin-bottom{
  margin-bottom:20%;
  @media (max-width: 510px){
    max-height:100vh;

    margin-bottom:0% !important;
  }
}


.marg-top-xs{
  @media (min-width: 310px){
    margin-top:20vh !important;
  }
  @media (min-width: 486px){
    margin-top:0vh !important;
  }
  @media (min-width: 886px){
    margin-top:0vh !important;
  }
}

.marg-top-md{
  @media (min-width: 875px){
    background-color: transparent !important;
  }
  @media (min-width: 775px){
    border-radius: 15px;
    background-color: #ffffff9A;
    p{
      color:$primary !important;
    }
  }
  @media (max-width: 775px){
    margin-top:60vh !important;
  }
  @media (max-width: 700px){
    margin-top:54vh !important;
  }
  @media (max-width: 595px){
    margin-top:38vh !important;
  }
}

.bg-md-none{
  background-color: white !important;
  @media (min-width: 886px){
    background-color: #ffffff3A !important;
  }
}

.navbar{
  @media (min-width: 767px)
  {
    background-color:white;

  }
}

.navbar-brand h1{
  @media (min-width: 767px) {
    color: $primary;
  }
}

.nav-item {
  border-bottom:0.5px solid transparent !important;
  a {
    color: #2746985A !important;
  }
  &.active{
    border-bottom:0.5px solid $primary !important;
    a {
      color: $primary !important;
    }
  }
}

.nav-item-white {
  border-bottom:0.5px solid transparent !important;
  a {
    color: white !important;
  }
  &.active{
    border-bottom:0.5px solid white !important;
    a {
      color: white !important;
    }
  }
}

.hide-scrollbar::-webkit-scrollbar{
  display: none;
}


.hide-scrollbar{
  -ms-overflow-style: none;
}

.perso-nav{
  position: static;
  margin-top: 0%;
  @media (max-width: 925px){
    position:absolute !important;
    min-height:200px !important;
    margin-top:16vh;
    background-color: white;
    width:100%;
    margin-left: 0%;
    right:0;
    padding-right: 20px !important;
  }
  @media (max-width: 725px){
    margin-top:20vh;
  }
}

.media-registre{
  left:44%;
  @media (max-width: 925px){
    left:20%;
  }
}

.media-mt{
  @media (max-width: 925px){
    margin-top:-10%;
  }
}

.left-responsive{
  left:30%;
  @media (max-width: 886px){
    left:10%;
  }
}

.resize-se-titre{

  @media (min-width: 300px) and (max-width: 414px){
    font-size:2rem !important;
  }
}
.resize-se-sous-titre{

  @media (min-width: 300px) and (max-width: 320px){
    font-size:1rem !important;
  }
}

.w-lg-50{

  @media (min-width: 992px){
    width:50% !important;
  }
}


.faq{
  background-color: #4B57CF16;
  @media (max-width: 886px){
    background-color: #25AE98A4;
  }
}


.illus_acc{
  background-image: url('/illus_mushing_bis.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 886px){
    background-size: 120%;
    background-position: top;
  }
}
.bg-others{
  background-image: url('/bg-others.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  @media (max-width: 886px){
    background-size: 520%;
    background-position: center;
  }
}

.bg-test{
  background-image: url('/landing_web_1.svg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  @media (max-width: 886px){
    background-size: 220%;
    background-position: right;
  }
}

.bg-top-para{
  background-image: url('/top_paralax.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  @media (max-width: 886px){
    background-size: 220%;
    background-position: right;
  }
}

.media-top-responsive{
  @media (max-width: 906px){
    margin-top:30%;
  }
  @media (max-width: 506px){
    margin-top:5%;
  }
}

.media-vh{
  min-height: 80vh;
  @media (max-width: 886px){
    min-height: 70vh !important;
    max-height: 120vh !important;
  }
  @media (max-width: 350px){
    min-height: 70vh !important;
    max-height: 220vh !important;
  }
}


.media-gestion{
  min-height: 60vh;
  @media (max-width: 886px){
    min-height: 100vh !important;
  }

}

.media-planning{
  max-height: 65vh;
  @media (max-width: 886px){
    max-height: 100% !important;
  }
}

.media-planning img{
  @media (max-width: 886px){
    max-height: 430px !important;
  }
}

.bg-triangle{
  background-image: url('/bg-triangle.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  @media (max-width: 1186px){
    background-size: 200%;
    background-position: top;
  }
}

.bg-contact{
  background-image: url('/contact_bg.svg');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: left;
  @media (max-width: 886px){
    background-size: 100%;
    background-position: top;
  }
}

.illus_acc_new{
  background-image: url('/new_ills.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  @media (max-width: 886px){
    background-size: 100%;
    background-image: url('/illus_new_phone.svg');
    background-position: top;

  }
}

.illus_acc_new_good{
  background-image: url('/bg-new.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  @media (max-width: 886px){
    background-size: 100%;
    background-image: url('/illus_new_phone.svg');
    background-position: top;

  }
}


.perso-alert-success{
  background-color: #35539AA0;
  width:100%;
  height:100vh;
  z-index:99;
  position:fixed;
  top:0;
  color:white;
}

.msg-input{
    min-height: 100px;
    height:100px;
    @media (max-width: 886px){
      min-height: 230px !important;
      height:230px !important;
    }
}

.illus_tarifs{
  background-image: url('/illus_tarifs.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.rounded-button{
  border-radius:30px;
}

.no-decoration{
  text-decoration: none;
  list-style: none;
}

.cursor-pointer{
  cursor: pointer !important;
}

.hover-animation{
  transition: 0.3s ease-in-out;
  border-radius: 10px;

  &:hover{
    background-color: $primary;
    color:white;
    border-radius: 10px;
  }
}

.img_100{
  max-width: 300px;
}
.img_100 img{
  max-width: 300px;
  margin-top:auto;
  margin-bottom:auto;
}

.img_200{
  max-width: 400px;
}
.img_200 img{
  max-width: 400px;
  margin-top:auto;
  margin-bottom:auto;
}

.w-md-50{
  @media (min-width: 776px){
    width:50% !important;
  }
}
.w-md-25{
  @media (min-width: 876px){
    width:35% !important;
  }
}

.custom-width-illus{
  width:85%;
  @media (max-width: 776px){
    width:100% !important;
  }
}

.resize-front_page{

  @media (min-width: 988px) and (max-width:1090px){
      width:80% !important;
  }

}

.bg-green{
    background-color: #44B4AB;
}

.bg-green-opac{
    background-color: #44B4AB4A;
}

.illus_acc_chien{
  background-image: url('/bg-chien.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 776px){
    background-size: cover;
  }
}

.text-green{
  color:#44B4ABA0;
}

.bg-tarifs{
  background-image: url('/bg-tarifs.svg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  @media (max-width: 776px){
    background-size: cover;
  }
}


.illus_devices{
  background-image: url('/every_devices.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
  @media (max-width: 776px){
    background-position: top;
    background-size:100% !important;
  }

  @media (max-width: 1176px){
    background-size:40%;
  }
}

.footer {
  //  position: absolute;
  //  bottom: 0 !important;
    width: 100%;
    height: 30vh;
    padding-bottom: 0px;
    background: #000000;
    color: #FFFFFF;
}


// body {
//     background-color: white !important;
// }

.row{
  margin:0px;
  padding:0px;
}


.text-danger{
  color:red;
}

.perso-input input{
	display:none;
}

.perso-input label{
	padding: 8px;
	margin:5px;
	border:1px solid transparent;
	border-radius:13px;
}

.perso-input-big label{
	padding: 12px;
  font-size: 1.4rem;
	margin:5px;
	border:1px solid transparent;
	border-radius:13px;
  font-weight: 200;
}

.perso-input label:hover{
	cursor:pointer;
	border: 1px solid $primary;
  color: $primary;
}

.perso-input-big label:hover{
	cursor:pointer;
  border-color: transparent !important;
	border: 1px solid $primary !important;
}

.perso-input input:checked+label{
	border:1px solid $primary;
	color:$primary;
}


.under-div-100 div {
  width:100%;
}
.under-div-75 div{
  min-width:100%;
}

.big_label label{
  font-size:1.3rem;
}


.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: $primary;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}



.anim_st1{
  transform: translateY(150px);
animation: popup 10s 6.5s ease infinite;
}

@keyframes popup {
  0% {
    transform: translateY(150px);
  }
  34% {
    transform: translateY(20px);
  }
  37% {
    transform: translateY(150px);
  }
  100% {
    transform: translateY(150px);
  }
}

// .text-effect{
//   &:hover {
//   	.entry-title{
// 			background-position: -100% 0;
// 		}
//   }
// }


.media-fond{
  margin-top:50px;
  margin-right:150px;
  @media (max-width: 776px){
    margin-top:330px;
    margin-left: 10%;
    margin-right:0px;
  }
}

.entry-title{
  background: linear-gradient(to right, rgba(255,255,255,0) 50%, rgba(37, 174, 152,0.5) 50%);
  background-size: 200%;
  background-position: 0 0;
  display: inline;
  transition: .5s ease-in-out;
  animation: highlight-text 3s 0.5s ease 1 forwards;
}

@keyframes highlight-text {
  100% {
    background-position: -100% 0;
  }
}


.little-text{
  font-size:0.8rem;
  opacity:0.7;
  max-width: 75%;
}


.arrows {
	width: 80px;
	height: 80px;
	// position: absolute;
	// left: 50%;
	// margin-left: -20px;
	// bottom: 20px;
  margin-left: 70%;
}

.arrows-mobile {
	width: 80px;
	height: 80px;
	// position: absolute;
	// left: 50%;
	// margin-left: -20px;
	// bottom: 20px;
  margin-left: 20% !important;
}

.arrows path {
	stroke:$primary;
  fill: transparent;
	stroke-width: 4px;
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite;
}

@keyframes arrow
{
0% {opacity:0; transform:translateY(10px)}
80% {opacity:0.2; transform:translateY(50px); stroke:#57B4CD;}
100% {opacity:0; transform:translateY(50px)}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0; transform:translateY(10px)}
80% {opacity:0.2; transform:translateY(50px); stroke:#57B4CD;}
100% {opacity:0; transform:translateY(50px)}
}

.arrows path.a1 {
	animation-delay:-0.5s;
	-webkit-animation-delay:-0.5s; /* Safari and Chrome */
}
